import React from 'react'
import { graphql } from 'gatsby'
import BookstoreView from '../components/BookstoreView'

const Bookstore = ({ data }) => {
  const books = data.books.edges.map(item => item.node)
  const categories = data.settings.childConfigYaml.categories
  return (
    <div>
      <BookstoreView books={books} categories={categories} />
    </div>
  )
}

export default Bookstore

export const pageQuery = graphql`
  query BookstorePageQuery {
    books: allMarkdownRemark(
      sort: {fields: frontmatter___inStock, order: DESC}
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            sku
            title
            author
            price
            category
            inStock
            gallery {
              image {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    settings: file(name: {eq: "categories"}) {
      childConfigYaml {
        categories
      }
    }
  }
`
