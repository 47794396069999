import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from './Header'
import Footer from './Footer'
import Item from './Item'
import { saveCategories } from '../state/bookstoreSlice'

export class BookstoreView extends Component {

  handleCategorySelection(category) {
    const categories = this.props.bookstore.selectedCategories
    this.props.dispatch(saveCategories({
      selectedCategories: categories.includes(category)
        ? categories.filter(cat => cat !== category)
        : [...categories, category]
    }))
  }

  getUniqueCategories(books) {
    return books.map(book => book.frontmatter.category).filter((value, index, self) => self.indexOf(value) === index).sort()
  }

  filterBooks(books) {
    return books.filter(
      book => this.props.bookstore.selectedCategories.includes(book.frontmatter.category)
    )
  }

  componentDidMount() {
    // selecting checkboxes for preloaded saved categories
    this.props.bookstore.selectedCategories.map((category) => {
      const input = document.getElementById(category);
      if (input) input.checked = true
    })
  }

  render() {
    const { books } = this.props
    const selectedCategories = this.props.bookstore.selectedCategories
    const categories = this.getUniqueCategories(books)
    const filteredBooks = (selectedCategories.length > 0) ? this.filterBooks(books) : books

    return (
      <div className="bookstore-box">
        <div>
          <Header />
          <div className="bookstore-container container">
            <div className="bookstore-container__menu">
              <span className="menu-category">Категорії книг</span>
              {categories.map((category, index) => {
                return (
                  <label className='menu-item' key={index}>
                    <input type='checkbox' id={category} onClick={() => this.handleCategorySelection(category)} />
                    <span className='indicator'></span>
                    {category}
                  </label>
                )
              })}
              
            </div>
            <div className="books-card-row">
              {filteredBooks.map(item => (
                <Item key={item.id} bookitem={item} />
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div> 
    )
  }
}

export default connect(state => ({ 
  bookstore: state.bookstore 
}))(BookstoreView)