import React from 'react'
import { useDispatch } from 'react-redux'
import { addToCart } from '../state/cartSlice'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import DynamicImage from './DynamicImage'

const Item = ({ bookitem }) => {
  const dispatch = useDispatch()
  const slug = bookitem.fields.slug
  const lang = bookitem.frontmatter.lang
  const DEFAULT_LANG = "ua"
  const langs = () => lang === DEFAULT_LANG ? slug.replace("/" + DEFAULT_LANG, "") : slug

  return (
    <div className="books-card">
      <Link to={slug} className="img-box">
        <div>
          {<DynamicImage img={bookitem.frontmatter.gallery[0].image} />}
        </div>
      </Link>
      <div className="text-box">
        <Link to={slug} className="title-box">
          <span className="title-box__title">{bookitem.frontmatter.title}</span>
          <span className="title-box__author">
            {bookitem.frontmatter.author}
          </span>
        </Link>
        <div className="price-box">
          <div>
            <span className="price-box__value">{bookitem.frontmatter.price}</span>
            <span className="price-box__measure">грн</span>
          </div>
          { bookitem.frontmatter.inStock
            ?
            <div className="shopping-link"
              onClick={e => {
                e.preventDefault();
                dispatch(addToCart({...bookitem.frontmatter, slug}))
              }}>
              <div className="shopping-link-img">
                <StaticImage src="../images/book_cart.png" alt="book_cart_icon" />
              </div>
            </div>
            :
            <div className="out-of-stock">немає<br />в наявності</div>
          }
        </div>
      </div>
    </div>
  )
}

export default Item
